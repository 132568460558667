import { jsx as _jsx } from "react/jsx-runtime";
import { rangeListButtons, RANGE_LIST, } from '@components/Shared/DatePicker/enums';
const RangeList = ({ onDateRangeSelect, setSelectedRangeList, selectedRangeList, }) => {
    const getDateRange = (type) => {
        const today = new Date();
        let startDate = null;
        let endDate = null;
        let lastWeekStartDate = new Date();
        const getStartOfWeek = (date) => {
            const day = date.getDay();
            const diff = day === 0 ? 6 : day - 1; // Adjust when day is Sunday
            const startOfWeek = new Date(date);
            startOfWeek.setDate(date.getDate() - diff);
            startOfWeek.setHours(0, 0, 0, 0);
            return startOfWeek;
        };
        const getEndOfWeek = (startOfWeek) => {
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6);
            endOfWeek.setHours(23, 59, 59, 999);
            return endOfWeek;
        };
        ({ startDate, endDate, lastWeekStartDate } = getRangeByType(type, startDate, endDate, today, getStartOfWeek, getEndOfWeek, lastWeekStartDate));
        return { startDate, endDate };
    };
    const handleClick = (type) => {
        if (type === RANGE_LIST.CUSTOM) {
            setSelectedRangeList(type);
            return;
        }
        const range = getDateRange(type);
        setSelectedRangeList(type);
        onDateRangeSelect(range);
    };
    return (_jsx("div", { className: "flex flex-col w-[160px] h-[348px] items-start text-sm font-normal text-gray-900", children: rangeListButtons.map((type) => (_jsx("button", { className: `py-2 text-left ${selectedRangeList === type ? 'text-blue-700 font-medium' : ''}`, onClick: () => handleClick(type), children: type }, type))) }));
};
export default RangeList;
export const getRangeByType = (type, startDate, endDate, today, getStartOfWeek, getEndOfWeek, lastWeekStartDate) => {
    switch (type) {
        case RANGE_LIST.TODAY:
            startDate = endDate = today;
            break;
        case RANGE_LIST.YESTERDAY:
            startDate = endDate = new Date(today.setDate(today.getDate() - 1));
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
        case RANGE_LIST.THIS_WEEK:
            startDate = getStartOfWeek(today);
            endDate = getEndOfWeek(startDate);
            break;
        case RANGE_LIST.LAST_WEEK:
            lastWeekStartDate = getStartOfWeek(new Date(today.setDate(today.getDate() - 7)));
            startDate = lastWeekStartDate;
            endDate = getEndOfWeek(startDate);
            break;
        case RANGE_LIST.THIS_MONTH:
            startDate = new Date(today.getFullYear(), today.getMonth(), 1);
            endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
        case RANGE_LIST.LAST_MONTH:
            startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            endDate = new Date(today.getFullYear(), today.getMonth(), 0);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 999);
            break;
        default:
            startDate = endDate = new Date();
            break;
    }
    return { startDate, endDate, lastWeekStartDate };
};
