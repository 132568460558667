import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { isEmpty, sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Tabs from '@components/Base/Tabs';
import VoucherModal from '@components/VoucherModal';
import messages from '@containers/vouchers/messages';
import { SELECTED_TAB, } from '@containers/vouchers/types';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppSelector } from '@utils/hooks';
import { proviewConsoleVoucherFilterApplied } from '@utils/mixpanelActions';
import ClipBoardIcon from 'app/images/clipboard.svg';
import NoResultsFound from 'app/images/svgs/noResultsFound.svg';
import VoucherFilters from './VoucherFilters';
import IssuedVoucherTable from './VoucherTable/IssuedTable';
import { createColumns } from './VoucherTable/IssuedTable/columns';
import RedeemedVoucherTable from './VoucherTable/RedeemedTable';
import { createRedeemColumns } from './VoucherTable/RedeemedTable/columns';
const VoucherList = (props) => {
    const { voucherList, searchTerm, isLoading, setSearchTerm, updateAppliedFilters, vouchers, appliedFilters, selectedTab, handleSelectedTab, skuList, redeemedVouchersList, loadingRedeemedVouchers, handleCreateVoucher, loadingCreatedVoucher, createdVoucher, showVoucherSuccessModal, resetVoucherState, } = props;
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const showEmptyPage = isEmpty(appliedFilters);
    const [openVoucherModal, setOpenVoucherModal] = useState(false);
    const renderVouchersEmptyPage = isEmpty(voucherList) &&
        !isLoading &&
        !searchTerm &&
        showEmptyPage &&
        selectedTab === SELECTED_TAB.ISSUED;
    const renderVouchersNoResultsFoundPage = isEmpty(voucherList) &&
        (searchTerm || !showEmptyPage) &&
        selectedTab === SELECTED_TAB.ISSUED;
    const sortedData = sortBy(redeemedVouchersList, (order) => { var _a, _b, _c; return (_c = (_b = (_a = order.order_items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.coupon) === null || _c === void 0 ? void 0 : _c.code; });
    const renderRedeemedVouchersEmptyPage = sortedData.length === 0 &&
        !searchTerm &&
        !loadingRedeemedVouchers &&
        selectedTab === SELECTED_TAB.REDEEMED;
    const renderRedeemedVouchersNoResultsFoundPage = sortedData.length === 0 &&
        searchTerm &&
        selectedTab === SELECTED_TAB.REDEEMED;
    const columnsIssued = useMemo(() => {
        return createColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const columnsRedeemed = useMemo(() => {
        return createRedeemColumns().filter((column) => column !== null);
    }, [selectedTab]);
    const handleSearch = (value) => {
        setSearchTerm(value.replace(/%/g, ''));
    };
    const triggerFilterMPEvent = (filters) => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            const additionalProperties = {
                module: 'Vouchers',
                tab: 'issued',
            };
            const combinedProperties = Object.assign(Object.assign(Object.assign({}, additionalProperties), mixpanelProperties), { filteredBy: Object.assign({}, filters) });
            proviewConsoleVoucherFilterApplied(combinedProperties);
        }
    };
    const applyFilters = (filters) => {
        triggerFilterMPEvent(filters);
        updateAppliedFilters(filters);
    };
    const handleTabSelect = (value) => {
        handleSelectedTab(value);
    };
    const toggleModal = () => {
        setOpenVoucherModal((prev) => !prev);
        resetVoucherState();
    };
    return (_jsxs(_Fragment, { children: [renderVouchersEmptyPage ? (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.empty_voucher, descriptionMessage: messages.empty_voucher_description, descriptionWidth: "w-48" })) : (_jsx("div", { className: "flex w-full px-8 py-5", children: _jsxs("div", { className: "flex flex-col w-full", children: [_jsxs("div", { className: "flex flex-row-reverse justify-between items-center mb-4", children: [_jsxs("div", { className: "flex items-center gap-4", children: [_jsx(SearchInput, { initialValue: searchTerm, handleChange: (value) => handleSearch(value), placeholderMessageId: "app.containers.vouchers.search_vouchers", resetSearch: selectedTab }), _jsx(VoucherFilters, { updateAppliedFilters: applyFilters, vouchers: vouchers, appliedFilters: appliedFilters, skuList: skuList })] }), _jsxs("div", { className: "flex items-center", children: [_jsx(Tabs, { tabData: [
                                                {
                                                    label: _jsx(FormattedMessage, Object.assign({}, messages.issued)),
                                                    value: SELECTED_TAB.ISSUED,
                                                    isShow: true,
                                                },
                                                {
                                                    label: _jsx(FormattedMessage, Object.assign({}, messages.redeemed)),
                                                    value: SELECTED_TAB.REDEEMED,
                                                    isShow: true,
                                                },
                                            ], selectedValue: selectedTab, onSelect: handleTabSelect }), _jsx(Button, { className: "ml-2", variant: "base", "data-testid": "create-voucher-btn", onClick: toggleModal, children: _jsx(FormattedMessage, Object.assign({}, messages.create_voucher)) })] })] }), _jsx(_Fragment, { children: selectedTab === SELECTED_TAB.ISSUED ? (renderVouchersNoResultsFoundPage ? (_jsx(EmptyMessagePage, { icon: NoResultsFound, titleMessage: messages.empty_record_title, descriptionMessage: messages.empty_record_description, descriptionWidth: "w-48" })) : (_jsx(IssuedVoucherTable, { data: voucherList, columns: columnsIssued, isLoading: isLoading }))) : renderRedeemedVouchersEmptyPage ? (_jsx(EmptyMessagePage, { icon: ClipBoardIcon, titleMessage: messages.empty_voucher, descriptionMessage: messages.empty_redeemed_description, descriptionWidth: "w-48" })) : renderRedeemedVouchersNoResultsFoundPage ? (_jsx(EmptyMessagePage, { icon: NoResultsFound, titleMessage: messages.empty_record_title, descriptionMessage: messages.empty_record_description, descriptionWidth: "w-48" })) : (_jsx(RedeemedVoucherTable, { data: sortedData, columns: columnsRedeemed, isLoading: loadingRedeemedVouchers })) })] }) })), _jsx(VoucherModal, { skuList: skuList, handleCreateVoucher: handleCreateVoucher, openVoucherModal: openVoucherModal, toggleModal: toggleModal, loadingCreatedVoucher: loadingCreatedVoucher, createdVoucher: createdVoucher, showVoucherSuccessModal: showVoucherSuccessModal })] }));
};
export default VoucherList;
