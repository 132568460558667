import * as Sentry from '@sentry/react';
import { get, includes } from 'lodash';
import { triggerToast } from '@components/Base/Notification';
import { ROLE } from '@containers/app/types';
import { DEFAULT_TIMEZONE } from '@containers/user/types';
import { LOCAL_STORAGE_KEYS } from './data/enums';
import { getCurrentRole } from './data/roles';
import { AppLocale } from './messages';
export const parseJwt = (token) => {
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join(''));
        return JSON.parse(jsonPayload);
    }
    return {};
};
export const getHasuraHeaders = (graphToken) => {
    if (!graphToken) {
        return {};
    }
    return parseJwt(graphToken)['https://hasura.io/jwt/claims'];
};
export const getHasuraHeaderByName = (graphToken, name) => {
    const parsedHeaders = getHasuraHeaders(graphToken);
    return (get(parsedHeaders, name) || get(parsedHeaders, (name || '').toLowerCase()));
};
export const getRequiredPathRoleAccess = () => {
    return ROLE.TENANT_ADMIN;
};
export const validateRoleAccess = (roles, requiredRole) => includes(roles, requiredRole);
export const getDefaultContext = (graphToken) => {
    const roles = getHasuraHeaderByName(graphToken, 'X-Hasura-Allowed-Roles');
    const requiredRole = getRequiredPathRoleAccess();
    const haveAccess = validateRoleAccess(roles, requiredRole);
    return {
        headers: {
            'x-hasura-role': haveAccess ? requiredRole : getCurrentRole(roles),
        },
    };
};
export const handleEnterKeyPress = (event, callback) => {
    if (event.key === 'Enter') {
        if (typeof callback === 'function') {
            callback(event);
        }
    }
};
export const transformDataToOptions = (projects) => {
    const projectOptions = projects.map((project) => ({
        value: project.name,
        label: project.name,
        details: { id: project.id, location: project.region },
        description: project.description,
    }));
    return [
        {
            value: 'addNewProject',
            label: 'Add a new project',
            clickOnly: true,
        },
        {
            label: 'Switch to project',
            options: projectOptions,
        },
    ];
};
export const isTextTruncated = (element) => {
    return element.scrollWidth > element.clientWidth;
};
export const getPageCount = (totalCount, pagination) => {
    if (totalCount > 0) {
        return Math.ceil(totalCount / pagination.pageSize);
    }
    return totalCount || -1;
};
export const formatTimeZone = (timezone) => {
    return timezone.map((tz) => ({
        label: tz.description,
        value: tz.value,
    }));
};
export const formatUserList = (users) => {
    return users.map((user) => ({
        label: user.name,
        value: user.id,
        id: user.id,
    }));
};
export function formatRoles(role) {
    switch (role) {
        case ROLE.TENANT_ADMIN:
            return 'Tenant Admin';
        default:
            return role;
    }
}
export function formatRolesArray(roles) {
    if (!roles)
        return;
    function formatRole(role) {
        const words = role
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
        return words.join(' ');
    }
    return roles.map(formatRole).join(', ');
}
export const catchError = (error, skipToast) => {
    if (!skipToast) {
        triggerToast({
            variant: 'danger',
            setting: { position: 'top-right' },
            message: {
                title: 'Something went wrong',
                summary: error.message,
            },
        });
    }
    Sentry.captureException(error);
};
export const isBrowser = typeof window !== 'undefined';
export const getItemFromLocalStorage = (key) => {
    return isBrowser ? localStorage.getItem(key) : null;
};
export const setItemInLocalStorage = (key, value) => {
    if (isBrowser) {
        localStorage.setItem(key, value);
    }
};
export const getDefaultLocale = () => {
    var _a;
    const storedLanguage = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
    if (storedLanguage) {
        return (_a = JSON.parse(storedLanguage)) === null || _a === void 0 ? void 0 : _a.value;
    }
    return AppLocale.English;
};
export const getDefaultTimezone = () => {
    const timeZone = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.TIMEZONE);
    if (timeZone) {
        return JSON.parse(timeZone);
    }
    const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if ((zoneName || '').toLowerCase().includes('calcutta')) {
        return DEFAULT_TIMEZONE;
    }
    return {
        label: zoneName,
        value: zoneName,
    };
};
export const showNotification = (message) => {
    triggerToast({
        variant: message.variant,
        setting: { position: 'top-right' },
        message,
    });
};
export const getMixpanelProperties = (user, projectName, flowType) => {
    const { id: orgId = null, name: orgName } = user.tenant;
    const { id: userId = null, currentRole: userRole } = user;
    const mixpanelProperties = Object.assign(Object.assign({ orgId,
        orgName,
        userId,
        userRole, platformName: 'Proview Console', version: 'V8' }, (projectName && { projectName })), (flowType && { flowType }));
    return mixpanelProperties;
};
