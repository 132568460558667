import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/Vouchers/Filters/index';
import { hasNonEmptyFilters } from '@containers/vouchers/helpers';
const VoucherFilters = (props) => {
    const { updateAppliedFilters, vouchers, appliedFilters, skuList } = props;
    const [showFilter, setShowFilter] = useState(false);
    const toogleFilterView = () => {
        setShowFilter((prev) => !prev);
    };
    const hasAppliedFilters = useMemo(() => {
        return hasNonEmptyFilters(appliedFilters);
    }, [appliedFilters]);
    return (_jsxs(_Fragment, { children: [_jsx(FilterButton, { hasAppliedFilters: hasAppliedFilters, onClick: toogleFilterView }), _jsx(Filters, { updateAppliedFilters: updateAppliedFilters, showFilter: showFilter, toogleFilterView: toogleFilterView, vouchers: vouchers, appliedFilters: appliedFilters, skuList: skuList })] }));
};
export default VoucherFilters;
