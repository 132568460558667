import isObject from 'lodash/isObject';
import some from 'lodash/some';
import { ValidVoucher } from '@utils/dateFormat';
import { SELECTED_TAB } from './types';
export const formatInitialFilters = (searchParams) => {
    return () => {
        const queryParams = {};
        for (const [key, value] of searchParams) {
            try {
                queryParams[key] = JSON.parse(decodeURIComponent(value));
            }
            catch (_a) {
                try {
                    queryParams[key] = decodeURIComponent(value);
                }
                catch (e) {
                    console.error('Failed to decode URI: ', e);
                }
            }
        }
        return {
            filters: queryParams.filters || {},
            search: queryParams.search != null && queryParams.search !== ''
                ? String(queryParams.search).replace(/%/g, '')
                : '',
            tab: queryParams.tab || SELECTED_TAB.ISSUED,
        };
    };
};
export const hasNonEmptyFilters = (filters) => {
    return some(filters, (value) => {
        if (isObject(value)) {
            return some(value, Boolean);
        }
        return Boolean(value);
    });
};
export const getFormattedFilters = (appliedFilters) => {
    const { selectedSku, selectedVouchers, selectedExpiresDateRange, selectedCreatedRange, } = appliedFilters;
    return Object.assign(Object.assign(Object.assign(Object.assign({}, ((selectedSku === null || selectedSku === void 0 ? void 0 : selectedSku.length) && {
        sku_coupons: {
            sku_id: { _in: selectedSku.map(({ value }) => value) },
        },
    })), ((selectedVouchers === null || selectedVouchers === void 0 ? void 0 : selectedVouchers.length) && {
        code: { _in: selectedVouchers.map(({ value }) => value) },
    })), (selectedExpiresDateRange &&
        selectedExpiresDateRange.startDate &&
        selectedExpiresDateRange.endDate && {
        valid_till: {
            _gte: selectedExpiresDateRange.startDate,
            _lte: selectedExpiresDateRange.endDate,
        },
    })), (selectedCreatedRange &&
        selectedCreatedRange.startDate &&
        selectedCreatedRange.endDate && {
        created_at: {
            _gte: selectedCreatedRange.startDate,
            _lte: selectedCreatedRange.endDate,
        },
    }));
};
export const getFormattedSearchConditions = (searchTerm, selectedTab) => {
    if (selectedTab === SELECTED_TAB.ISSUED) {
        return [
            { code: { _ilike: `%${searchTerm}%` } },
            { description: { _ilike: `%${searchTerm}%` } },
        ];
    }
    else {
        return [
            {
                order_items: {
                    coupon: {
                        code: {
                            _ilike: `%${searchTerm}%`,
                        },
                    },
                },
            },
            {
                order_items: {
                    coupon: {
                        description: {
                            _ilike: `%${searchTerm}%`,
                        },
                    },
                },
            },
        ];
    }
};
export const getCombinedNames = (skuCoupons) => {
    if (skuCoupons === null || skuCoupons === void 0 ? void 0 : skuCoupons.length) {
        return skuCoupons.map((coupon) => { var _a, _b; return (_b = (_a = coupon === null || coupon === void 0 ? void 0 : coupon.item) === null || _a === void 0 ? void 0 : _a.sku) === null || _b === void 0 ? void 0 : _b.name; }).join(', ');
    }
    else {
        return '-';
    }
};
export const getCreatedByName = (orderItems) => {
    var _a, _b, _c, _d, _e, _f, _g;
    return ((_c = (_b = (_a = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _a === void 0 ? void 0 : _a.coupon) === null || _b === void 0 ? void 0 : _b.created_by_details) === null || _c === void 0 ? void 0 : _c.name)
        ? orderItems[0].coupon.created_by_details.name
        : ((_f = (_e = (_d = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _d === void 0 ? void 0 : _d.coupon) === null || _e === void 0 ? void 0 : _e.created_by_details) === null || _f === void 0 ? void 0 : _f.email)
            ? (_g = orderItems[0].coupon.created_by_details) === null || _g === void 0 ? void 0 : _g.email
            : '';
};
export const getExpiresOn = (orderItems) => {
    var _a, _b;
    return ((_b = (_a = orderItems === null || orderItems === void 0 ? void 0 : orderItems[0]) === null || _a === void 0 ? void 0 : _a.coupon) === null || _b === void 0 ? void 0 : _b.valid_till)
        ? ValidVoucher(new Date(orderItems[0].coupon.valid_till))
        : '-';
};
export const validateNumericInput = (value) => {
    return /^\d*$/.test(value) || value === '';
};
export const formateVoucherPayload = (maxUse, voucherDiscount, voucherDescription, selectedSku, expiryDate) => {
    const voucherData = {
        max_count: maxUse ? parseInt(maxUse) : 1,
        discount_percentage: voucherDiscount ? parseInt(voucherDiscount) : 0,
        description: voucherDescription,
        sku_id: selectedSku ? parseInt(selectedSku.value) : 0,
    };
    if (expiryDate) {
        voucherData['valid_till'] = expiryDate;
    }
    return voucherData;
};
