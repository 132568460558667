export const ProctorPermission = {
    Projects: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Flows: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Events: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Proctors: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Schedule: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Settings: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Payments: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Exams: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
    Vouchers: {
        canView: false,
        canCreate: false,
        canDelete: false,
        canEdit: false,
    },
};
