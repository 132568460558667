import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import VoucherList from '@components/Vouchers';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppSelector, useAppDispatch } from '@utils/hooks';
import { proviewConsoleVoucherPageViewedEvent } from '@utils/mixpanelActions';
import { formatInitialFilters, getFormattedFilters, getFormattedSearchConditions, } from './helpers';
import messages from './messages';
import { getPayVouchersAction, getSkuAction, getRedeemedVoucherAction, createVoucherAction, resetVoucherSuccessState, } from './slice';
import { SELECTED_TAB } from './types';
const Vouchers = () => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const parseInitialFilters = formatInitialFilters(searchParams);
    const filterDetails = parseInitialFilters();
    const { filters, search, tab } = filterDetails;
    const [searchTerm, setSearchTerm] = useState(search);
    const [selectedTab, setSelectedTab] = useState(tab);
    const [appliedFilters, updateAppliedFilters] = useState(filters);
    const { data: vouchersData, isLoading, error, skus: { skuList: skuData }, redeemedVouchers, newlyCreatedVouchers, } = useAppSelector((state) => state.voucherList);
    const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
    const { data: redeemedVouchersList, isLoading: loadingRedeemedVouchers } = redeemedVouchers;
    const { data: createdVoucher, isLoading: loadingCreatedVoucher, isSuccess: showVoucherSuccessModal, } = newlyCreatedVouchers;
    const updateURLParams = (tab) => {
        setSearchParams((params) => {
            params.set('tab', tab || selectedTab);
            const filters = Object.entries(appliedFilters).reduce((acc, [key, value]) => {
                if (value) {
                    if (Array.isArray(value)
                        ? value.length > 0
                        : typeof value === 'object'
                            ? Object.keys(value).length > 0
                            : true) {
                        acc[key] = value;
                    }
                }
                return acc;
            }, {});
            if (Object.keys(filters).length > 0) {
                params.set('filters', JSON.stringify(filters));
            }
            else {
                params.delete('filters');
            }
            if (searchTerm) {
                params.set('search', searchTerm.replace(/%/g, ''));
            }
            else {
                params.delete('search');
            }
            return params;
        }, { replace: true });
    };
    const handleSelectedTab = (value) => {
        setSelectedTab(value);
    };
    const vouchers = useMemo(() => {
        return vouchersData.map((voucher) => ({
            label: voucher.code,
            value: voucher.code,
        }));
    }, [vouchersData]);
    const skusList = useMemo(() => {
        return skuData.map((session) => ({
            label: session.name,
            value: session.id,
        }));
    }, [skuData]);
    useEffect(() => {
        const baseSearchConditions = getFormattedSearchConditions(searchTerm, selectedTab);
        const filters = getFormattedFilters(appliedFilters);
        if (selectedTab === SELECTED_TAB.ISSUED) {
            updateURLParams();
            dispatch(getPayVouchersAction({
                searchConditions: searchTerm ? baseSearchConditions : {},
                conditions: filters,
            }));
        }
        else {
            updateURLParams();
            dispatch(getRedeemedVoucherAction({
                searchConditions: searchTerm ? baseSearchConditions : {},
                conditions: filters,
            }));
        }
    }, [searchTerm, appliedFilters, selectedTab, createdVoucher]);
    useEffect(() => {
        if (isMixpanelInitialized && user) {
            const mixpanelProperties = getMixpanelProperties(user);
            proviewConsoleVoucherPageViewedEvent(mixpanelProperties);
        }
    }, [isMixpanelInitialized, user.tenant]);
    useEffect(() => {
        if (!skuData.length) {
            dispatch(getSkuAction());
        }
    }, [skuData]);
    const handleCreateVoucher = (createVoucherPayload) => {
        dispatch(createVoucherAction(createVoucherPayload));
    };
    const resetVoucherState = () => {
        dispatch(resetVoucherSuccessState());
    };
    if (error) {
        triggerToast({
            variant: 'danger',
            setting: { position: 'top-right' },
            message: {
                title: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_header)),
                summary: _jsx(FormattedMessage, Object.assign({}, messages.error_voucher_fetch_body)),
            },
        });
        return _jsx(SomethingWentWrong, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Header, { details: { label: _jsx(FormattedMessage, Object.assign({}, messages.vouchers)) } }), _jsx(VoucherList, { appliedFilters: appliedFilters, updateAppliedFilters: updateAppliedFilters, voucherList: vouchersData, isLoading: isLoading, searchTerm: searchTerm, setSearchTerm: setSearchTerm, vouchers: vouchers, selectedTab: selectedTab, handleSelectedTab: handleSelectedTab, skuList: skusList, redeemedVouchersList: redeemedVouchersList, loadingRedeemedVouchers: loadingRedeemedVouchers, handleCreateVoucher: handleCreateVoucher, loadingCreatedVoucher: loadingCreatedVoucher, createdVoucher: createdVoucher, showVoucherSuccessModal: showVoucherSuccessModal, resetVoucherState: resetVoucherState })] }));
};
export default Vouchers;
export const showCreateVoucherErrorToast = () => {
    triggerToast({
        variant: 'danger',
        setting: { position: 'top-right' },
        message: {
            title: _jsx(FormattedMessage, Object.assign({}, messages.voucher_creation_failed)),
            summary: (_jsx(FormattedMessage, Object.assign({}, messages.voucher_creation_failed_description))),
        },
    });
};
