import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';
import DateInputBox from './DateInputBox';
import Header from './Header';
import './styles.css';
const DatePicker = ({ onChange, defaultDate, disablePastDate = false, isReadOnly = false, minDate, maxDate, disabledWeekend = false, }) => {
    const [selectedDate, setSelectedDate] = useState(defaultDate || null);
    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    const datePickerRef = useRef(null);
    const calendarRef = useRef(null);
    const [isDisplayDateView, setIsDisplayDateView] = useState(true);
    const handleDateChange = (newDate) => {
        if (!isReadOnly) {
            setSelectedDate(newDate);
            onChange(newDate);
        }
    };
    const handleMonthSelect = (newDate) => {
        handleDateChange(newDate);
    };
    const handleYearSelect = (year) => {
        if (selectedDate) {
            const newDate = new Date(year, selectedDate.getMonth(), selectedDate.getDate());
            handleDateChange(newDate);
        }
    };
    const handleCalendarIconClick = () => {
        handleDateView(true);
        setIsHeaderVisible((prev) => !prev);
    };
    const handleInputFocus = () => {
        setIsHeaderVisible(false);
    };
    const handleDateView = (isDisplay) => {
        setIsDisplayDateView(isDisplay);
    };
    useEffect(() => {
        const handleClickEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setIsHeaderVisible(false);
            }
        };
        const handleClickOutside = (event) => {
            if (datePickerRef.current &&
                !datePickerRef.current.contains(event.target) &&
                calendarRef.current &&
                !calendarRef.current.contains(event.target)) {
                setIsHeaderVisible(false);
            }
        };
        document.addEventListener('keydown', handleClickEscapeKey);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleClickEscapeKey);
        };
    }, []);
    return (_jsxs("div", { ref: datePickerRef, className: "date-picker relative", children: [_jsx(DateInputBox, { date: selectedDate, onDateChange: handleDateChange, onIconClick: handleCalendarIconClick, onInputFocus: handleInputFocus }), isHeaderVisible && (_jsxs("div", { ref: calendarRef, className: 'calenderview-container h-fit bg-white border shadow-lg rounded-lg p-4 mt-1', children: [_jsx(Header, { onMonthSelect: handleMonthSelect, onYearSelect: handleYearSelect, defaultDate: selectedDate || new Date(), onDateChange: handleDateChange, handleDateView: handleDateView }), _jsx("div", { className: "mt-1", children: isDisplayDateView && (_jsx(CalendarView, { startDate: null, endDate: null, date: selectedDate || new Date(), selectedDate: selectedDate, onDateSelect: handleDateChange, disablePastDate: disablePastDate, isReadOnly: isReadOnly, minDate: minDate, maxDate: maxDate, disabledWeekend: disabledWeekend })) })] }))] }));
};
export default DatePicker;
