import { all, call, put, takeLatest } from 'redux-saga/effects';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';
import { showCreateVoucherErrorToast } from '.';
import { GET_SKU_LIST_QUERY, GET_VOUCHER_LIST_QUERY, GET_REDEEMED_VOUCHER_LIST_QUERY, CREATE_VOUCHER_MUTATION, } from './queries';
import { getPayVouchersAction, getPayVouchersErrorAction, getPayVouchersSuccessAction, getSkuAction, getSkuSuccessAction, getSkuErrorAction, getRedeemedVoucherAction, getRedeemedVoucherSuccessAction, getRedeemedVoucherErrorAction, createVoucherAction, createVoucherSuccessAction, createVoucherErrorAction, } from './slice';
export function* getVoucherListSaga(action) {
    const { searchConditions, conditions } = action.payload;
    const queryVariables = {
        limit: 10,
        offset: 0,
        searchExpression: searchConditions,
        conditions: Object.assign({}, conditions),
    };
    try {
        const response = yield call(fetchData, {
            queryString: GET_VOUCHER_LIST_QUERY,
            queryKey: 'pay_coupon',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        if (response) {
            yield put(getPayVouchersSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getPayVouchersErrorAction(error));
    }
}
export function* getSkuListSaga() {
    try {
        const response = yield call(fetchData, {
            queryString: GET_SKU_LIST_QUERY,
            queryKey: 'pay_sku',
            forceRefresh: true,
        });
        if (response) {
            yield put(getSkuSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getSkuErrorAction(error));
    }
}
export function* getRedeemedVoucherListSaga(action) {
    const { searchConditions, conditions } = action.payload;
    const queryVariables = {
        limit: 10,
        offset: 0,
        searchExpression: searchConditions,
        conditions: Object.assign({}, conditions),
    };
    try {
        const response = yield call(fetchData, {
            queryString: GET_REDEEMED_VOUCHER_LIST_QUERY,
            queryKey: 'pay_order',
            queryVariables: queryVariables,
            forceRefresh: true,
        });
        if (response) {
            yield put(getRedeemedVoucherSuccessAction(response));
        }
    }
    catch (error) {
        yield put(getRedeemedVoucherErrorAction(error));
    }
}
export function* createVouchersSaga({ payload, }) {
    try {
        const response = yield call(postData, {
            queryString: CREATE_VOUCHER_MUTATION,
            payload,
            spreadPayload: true,
        });
        if (response) {
            yield put(createVoucherSuccessAction(response.data.pay_create_coupon));
        }
    }
    catch (error) {
        showCreateVoucherErrorToast();
        yield put(createVoucherErrorAction(error));
    }
}
export function* redeemedVoucherListSaga() {
    yield takeLatest(getRedeemedVoucherAction.type, getRedeemedVoucherListSaga);
}
export function* voucherListSaga() {
    yield takeLatest(getPayVouchersAction.type, getVoucherListSaga);
}
export function* skuListSaga() {
    yield takeLatest(getSkuAction.type, getSkuListSaga);
}
export function* createVoucherSaga() {
    yield takeLatest(createVoucherAction.type, createVouchersSaga);
}
export function* voucherSaga() {
    yield all([
        voucherListSaga(),
        skuListSaga(),
        redeemedVoucherListSaga(),
        createVoucherSaga(),
    ]);
}
