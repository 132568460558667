import { VOUCHER_STATUS } from '@containers/vouchers/types';
export const formatDate = (date, type) => {
    const storedTimezone = localStorage.getItem('timezone');
    const timezone = storedTimezone
        ? JSON.parse(storedTimezone).value
        : undefined;
    // By default it takes the system timezone
    const day = date.toLocaleDateString('en-US', {
        weekday: 'short',
        timeZone: timezone,
    });
    const dayOfMonth = date.getDate();
    const month = date.toLocaleDateString('en-US', {
        month: 'short',
        timeZone: timezone,
    });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: timezone,
    });
    switch (type) {
        case 'withOutTime':
            return `${day} ${dayOfMonth}, ${month} ${year}`;
        case 'withTime':
            return `${day} ${dayOfMonth}, ${month} ${year} at ${time}`;
        default:
            return new Date().toISOString();
    }
};
export const getDaysAgo = (pastDate) => {
    const currentDate = new Date();
    if (pastDate > currentDate) {
        return '0 days';
    }
    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    const dayLabel = daysDifference === 1 ? 'day' : 'days';
    return `${daysDifference} ${dayLabel}`;
};
export const formatTimeFromMinutes = (minutes) => {
    if (typeof minutes !== 'number' || isNaN(minutes) || minutes < 0) {
        return '00:00:00';
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = (minutes - Math.floor(minutes)) * 60;
    return [
        hours.toString().padStart(2, '0'),
        remainingMinutes.toString().padStart(2, '0'),
        Math.floor(seconds).toString().padStart(2, '0'),
    ].join(':');
};
export const ValidVoucher = (date) => {
    const currentDate = new Date();
    if (date < currentDate) {
        return VOUCHER_STATUS.EXPIRED;
    }
    return formatDate(date, 'withTime');
};
