import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements, } from 'react-router-dom';
import GlobalError from '@components/Error/GlobalError';
import NotFound from '@components/Error/NotFound';
import { initClientAction } from '@containers/app/slice';
import Login from '@containers/auth/login';
import FlowDetails from '@containers/flowDetails';
import Flows from '@containers/flowList';
import Home from '@containers/home';
import Layout from '@containers/layout';
import Projects from '@containers/projects';
import Sessions from '@containers/sessions';
import Vouchers from '@containers/vouchers';
import growthBook from '@utils/growthbook';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
const router = createBrowserRouter(createRoutesFromElements(_jsxs(_Fragment, { children: [_jsx(Route, { path: "/", element: _jsx(Login, {}), errorElement: _jsx(GlobalError, {}), children: _jsxs(Route, { element: _jsx(Layout, {}), children: [_jsx(Route, { path: "projects", element: _jsx(Projects, {}), children: _jsx(Route, { path: ":project_uuid", element: _jsx(Projects, {}) }) }), _jsx(Route, { path: "home", children: _jsx(Route, { index: true, element: _jsx(Home, {}) }) }), _jsxs(Route, { path: "flows", children: [_jsx(Route, { index: true, element: _jsx(Flows, {}) }), _jsx(Route, { path: ":flowId", element: _jsx(FlowDetails, {}) })] }), _jsx(Route, { path: "sessions", children: _jsx(Route, { index: true, element: _jsx(Sessions, {}) }) }), _jsx(Route, { path: "vouchers", children: _jsx(Route, { index: true, element: _jsx(Vouchers, {}) }) })] }) }), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] })));
const App = () => {
    const dispatch = useAppDispatch();
    console.log('Deployment 1');
    useEffect(() => {
        dispatch(initClientAction());
    }, [initClientAction, dispatch]);
    const { loading } = useAppSelector((state) => state.app);
    useEffect(() => {
        growthBook.loadFeatures({ autoRefresh: true });
        growthBook.setAttributes({
            url: window.location.href,
            browser: navigator.userAgent,
        });
    }, []);
    return (_jsx(GrowthBookProvider, { growthbook: growthBook, children: !loading && _jsx(RouterProvider, { router: router }) }));
};
export default App;
