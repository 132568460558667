import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';
import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import DateRangePicker from '@components/Shared/DateRangePicker';
import SidePanel from '@components/SidePanel';
import messages from './messages';
import './styles.css';
const Filters = (props) => {
    var _a, _b, _c, _d;
    const { updateAppliedFilters, showFilter, toogleFilterView, vouchers, appliedFilters, skuList, } = props;
    const [selectedSku, setSelectedSku] = useState((_a = appliedFilters.selectedSku) !== null && _a !== void 0 ? _a : null);
    const [selectedVouchers, setSelectedVouchers] = useState((_b = appliedFilters.selectedVouchers) !== null && _b !== void 0 ? _b : null);
    const [selectedExpiresDateRange, setSelectedExpiresDateRange] = useState((_c = appliedFilters.selectedExpiresDateRange) !== null && _c !== void 0 ? _c : null);
    const [selectedCreatedRange, setSelectedCreatedRange] = useState((_d = appliedFilters.selectedCreatedRange) !== null && _d !== void 0 ? _d : null);
    const handleClearFilters = () => {
        setSelectedVouchers(null);
        updateAppliedFilters({});
        setSelectedExpiresDateRange(null);
        setSelectedCreatedRange(null);
        setSelectedSku(null);
        toogleFilterView();
    };
    const handleApplyFilter = () => {
        const filters = {
            selectedSku: selectedSku,
            selectedVouchers: selectedVouchers,
            selectedExpiresDateRange: selectedExpiresDateRange,
            selectedCreatedRange: selectedCreatedRange,
        };
        toogleFilterView();
        updateAppliedFilters(filters);
    };
    return (_jsx(_Fragment, { children: _jsx(SidePanel, { show: showFilter, containerPadding: "p-0", onClose: toogleFilterView, allowCustomHeader: true, children: _jsxs("div", { className: "flex flex-col flex-1 justify-between", children: [_jsxs("div", { children: [_jsx("div", { className: "sticky top-0 border-b z-[1]", children: _jsxs("div", { className: "flex p-5 bg-white", children: [_jsxs("div", { className: "w-filter-header", children: [_jsx("div", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.filter)) }), _jsx("div", { className: "text-sm font-normal text-gray-600", children: _jsx(FormattedMessage, Object.assign({}, messages.customize_view)) })] }), _jsx("div", { "data-testid": "close_btn", role: "button", tabIndex: -1, className: "px-2 text-gray-600 cursor-pointer", onClick: toogleFilterView, onKeyPress: toogleFilterView, children: _jsx(XMarkIcon, { className: "w-6 h-6", "aria-hidden": "true" }) })] }) }), _jsxs("div", { className: "flex flex-col px-6 py-3 bg-white", children: [_jsx("div", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.code)) }), _jsx("div", { className: "pt-3", children: _jsx(Dropdown, { id: "voucher", isMulti: true, value: selectedVouchers, isSearchable: true, placeholder: _jsx(FormattedMessage, Object.assign({}, messages.select_code)), onChange: (voucher) => setSelectedVouchers(voucher), options: vouchers.length ? vouchers : [] }) })] }), _jsxs("div", { className: "flex flex-col px-6 py-3 bg-white", children: [_jsx("div", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.sku)) }), _jsx("div", { className: "pt-3", children: _jsx(Dropdown, { id: "sku-list", isMulti: true, value: selectedSku, isSearchable: true, placeholder: _jsx(FormattedMessage, Object.assign({}, messages.select_sku)), onChange: (sku) => {
                                                setSelectedSku(sku);
                                            }, options: skuList.length ? skuList : [] }) })] }), _jsxs("div", { className: "flex flex-col px-6 py-3 bg-white", children: [_jsx("div", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.expires_on_date)) }), _jsx("div", { className: "flex pt-3", children: _jsx(DateRangePicker, { onClickOnDonebtn: (range) => setSelectedExpiresDateRange(range) }) })] }), _jsxs("div", { className: "flex flex-col px-6 py-3 bg-white", children: [_jsx("div", { className: "text-base font-semibold text-gray-900", children: _jsx(FormattedMessage, Object.assign({}, messages.created_on_date)) }), _jsx("div", { className: "flex pt-3", children: _jsx(DateRangePicker, { onClickOnDonebtn: (range) => setSelectedCreatedRange(range) }) })] })] }), _jsx("div", { className: "sticky bottom-0", children: _jsxs("div", { className: "h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]", children: [_jsx(Button, { variant: "outline", onClick: handleClearFilters, children: _jsx(FormattedMessage, Object.assign({}, messages.clear)) }), _jsx("div", { className: "px-3", children: _jsx(Button, { onClick: handleApplyFilter, children: _jsx(FormattedMessage, Object.assign({}, messages.apply)) }) })] }) })] }) }) }));
};
export default Filters;
