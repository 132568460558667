import { defineMessages } from 'react-intl';
export const scope = 'app.component.voucherModal';
export default defineMessages({
    new_voucher: {
        id: `${scope}.new_voucher`,
        defaultMessage: 'New Voucher',
    },
    type: {
        id: `${scope}.type`,
        defaultMessage: 'Type',
    },
    discount: {
        id: `${scope}.discount`,
        defaultMessage: 'Discount',
    },
    discount_validation_message: {
        id: `${scope}.discount_validation_message`,
        defaultMessage: 'Discount cannot exceed 100%',
    },
    sku: {
        id: `${scope}.sku`,
        defaultMessage: 'SKU',
    },
    expiry: {
        id: `${scope}.expiry`,
        defaultMessage: 'Expiry',
    },
    create_Voucher: {
        id: `${scope}.create_Voucher`,
        defaultMessage: 'Create Voucher',
    },
    voucher_code: {
        id: `${scope}.voucher_code`,
        defaultMessage: 'Voucher Code',
    },
    quantity: {
        id: `${scope}.quantity`,
        defaultMessage: 'Quantity',
    },
    redemption_limit: {
        id: `${scope}.redemption_limit`,
        defaultMessage: 'Redemption Limit',
    },
    description: {
        id: `${scope}.description`,
        defaultMessage: 'Description',
    },
    tags: {
        id: `${scope}.tags`,
        defaultMessage: 'Tags',
    },
    voucher_Created: {
        id: `${scope}.voucher_Created`,
        defaultMessage: 'Voucher Created Successfully',
    },
    min_quantity: {
        id: `${scope}.min_quantity`,
        defaultMessage: 'Minimum quantity is 1',
    },
    min_max_use: {
        id: `${scope}.min_max_use`,
        defaultMessage: 'Minimum redemption limit is 1',
    },
});
