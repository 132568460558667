export const VOUCHER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EXPIRED: 'Expired',
    NO_EXPIRY: 'No Expiry',
    NONE: 'None',
    TIMES: 'times',
    NA: 'N/A',
    STATUS: 'status',
};
export const voucherStatus = [
    { label: VOUCHER_STATUS.ACTIVE, value: VOUCHER_STATUS.ACTIVE },
    { label: VOUCHER_STATUS.INACTIVE, value: VOUCHER_STATUS.INACTIVE },
];
export const VOUCHER_CREATION_TYPE = {
    BULK_CODES: 'Bulk Codes',
    STANDALONE_CODE: 'Standalone Code',
    VOUCHER_CREATION: 'voucherCreation',
};
export const voucherCreationType = [
    {
        label: VOUCHER_CREATION_TYPE.STANDALONE_CODE,
        value: VOUCHER_CREATION_TYPE.STANDALONE_CODE,
    },
    {
        label: VOUCHER_CREATION_TYPE.BULK_CODES,
        value: VOUCHER_CREATION_TYPE.BULK_CODES,
    },
];
export const SELECTED_TAB = {
    ISSUED: 'issued',
    REDEEMED: 'redeemed',
};
