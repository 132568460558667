import { defineMessages } from 'react-intl';
export const scope = 'app.components.voucher.filters';
export default defineMessages({
    filters_button: {
        id: `${scope}.filters_button`,
        defaultMessage: 'Filters',
    },
    filter: {
        id: `${scope}.filter`,
        defaultMessage: 'Filter',
    },
    customize_view: {
        id: `${scope}.customize_view`,
        defaultMessage: 'Customize your view by selectively including or excluding table elements.',
    },
    code: {
        id: `${scope}.code`,
        defaultMessage: 'Voucher Code',
    },
    status: {
        id: `${scope}.status`,
        defaultMessage: 'Status',
    },
    select_code: {
        id: `${scope}.select_code`,
        defaultMessage: 'Select vouchers',
    },
    created_on_date: {
        id: `${scope}.created_on_date`,
        defaultMessage: 'Created on date',
    },
    expires_on_date: {
        id: `${scope}.expires_on_date`,
        defaultMessage: 'Expires on date',
    },
    clear: {
        id: `${scope}.clear`,
        defaultMessage: 'Clear Filters',
    },
    apply: {
        id: `${scope}.apply`,
        defaultMessage: 'Apply',
    },
    select_sku: {
        id: `${scope}.select_sku`,
        defaultMessage: 'Select SKUs',
    },
    sku: {
        id: `${scope}.sku`,
        defaultMessage: 'SKU',
    },
    redeemed_by: {
        id: `${scope}.redeemed_by`,
        defaultMessage: 'Redeemed by',
    },
});
